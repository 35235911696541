// Import Firebase SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your Firebase configuration object (from Firebase Console)
const firebaseConfig = {
  apiKey: "AIzaSyAZSyPl6RjHQY2wo-UeXe-S7GJnnPkXnC0",
  authDomain: "mayadatabase-1df3f.firebaseapp.com",
  projectId: "mayadatabase-1df3f",
  storageBucket: "mayadatabase-1df3f.firebasestorage.app",
  messagingSenderId: "890074752161",
  appId: "1:890074752161:web:36ef4cded2f451880e7ce0",
  measurementId: "G-1C6BJ9RHWK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
export const analytics = getAnalytics(app);
